import { environmentName } from 'utils/environments'

const DEFAULT_APP_DATA = '0x0000000000000000000000000000000000000000000000000000000000000000'

export function getAppDataHash(): string {
  console.log(`---------------environment name -------------------- ` + environmentName)
  switch (environmentName) {
    case 'production':
      console.log('production mode')
      return process.env.REACT_APP_DATA_HASH_PRODUCTION || DEFAULT_APP_DATA // return '0x487B02C558D729ABAF3ECF17881A4181E5BC2446429A0995142297E897B6EB37' || DEFAULT_APP_DATA

    case 'ens':
      return process.env.REACT_APP_DATA_HASH_ENS || DEFAULT_APP_DATA

    case 'barn':
      return process.env.REACT_APP_DATA_HASH_BARN || DEFAULT_APP_DATA

    case 'staging':
      return process.env.REACT_APP_DATA_HASH_STAGING || DEFAULT_APP_DATA

    case 'pr':
      return process.env.REACT_APP_DATA_HASH_PR || DEFAULT_APP_DATA

    case 'development':
      return process.env.REACT_APP_DATA_HASH_DEVELOPMENT || DEFAULT_APP_DATA

    case 'local':
      return process.env.REACT_APP_DATA_HASH_LOCAL || DEFAULT_APP_DATA // return '0xA5DAE7A114F1BD6BB9B3FF976150380A95CB18856212DB555C25EF9D7801E9A4' || DEFAULT_APP_DATA

    default:
      break
  }
  return 'no'
}
